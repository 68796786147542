body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.main-content{
  padding: 2vmin;
}
.para {
  font-weight: bold;
  font-size: 19px;
  color: brown;
}